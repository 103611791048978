import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
  },
  {
    path: '/:eleccion/VE/:tipo?',
    name: 'VotoEnElExtranjero',
    component: () => import(/* webpackChunkName: "VotoEnElExtranjero" */ '@/views/VotoEnElExtranjero.vue'),
  },
  {
    path: '/:eleccion/ENT/VPC',
    name: 'VotosPorCandidaturas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "VotosPorCandidaturas" */ '../views/VotosPorCandidaturas.vue'),
  },
  {
    path: '/preguntas-frecuentes',
    name: 'PreguntasFrecuentes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "VotosPorCandidaturas" */ '@/views/PreguntasFrecuentes.vue'),
  },
  {
    path: '/:eleccion/ENT/VPP',
    name: 'VotosPorPartidoPolitico',
    component: () => import(/* webpackChunkName: "VotosPorPartidoPolitico" */ '@/views/VotosPorPartidoPolitico/Index.vue'),
  },
  {
    path: '/:eleccion/ENT/VPC',
    name: 'VotosPorDistritos',
    component: () => import(/* webpackChunkName: "VotosPorPartidoPolitico" */ '@/views/VotosPorDistritos/Index.vue'),
  },
  {
    path: '/:eleccion/DET/VPC/:distrito',
    name: 'DetalleDelDistrito',
    component: () => import(/* webpackChunkName: "DetalleDelDistrito" */ '@/views/VotosPorCandidaturas/DetalleDelDistrito.vue'),
  },
  {
    path: '/:eleccion/DET/VPP/:distrito',
    name: 'DetalleDelDistritoVPP',
    component: () => import(/* webpackChunkName: "DetalleDelDistritoVPP" */ '@/views/VotosPorPartidoPolitico/DetalleDelDistrito.vue'),
  },
  {
    path: '/:eleccion/DET/VPC/:distrito/:seccion',
    name: 'DetalleDelDistritoySeccion',
    component: () => import(/* webpackChunkName: "DetalleDelDistritoySeccion" */ '@/views/VotosPorCandidaturas/DetalleDelDistritoySeccion.vue'),
  },
  {
    path: '/:eleccion/DET/VPP/:distrito/:seccion',
    name: 'DetalleDelDistritoySeccionVPP',
    component: () => import(/* webpackChunkName: "DetalleDelDistritoySeccionVPP" */ '@/views/VotosPorCandidaturas/DetalleDelDistritoySeccion.vue'),
  },
  {
    path: '/:eleccion/DET/VPC/:distrito/:seccion/:casilla',
    name: 'DetalleDeCasilla',
    component: () => import(/* webpackChunkName: "DetalleDeCasilla" */ '@/views/VotosPorCandidaturas/DetalleDeCasilla.vue'),
  },
  {
    path: '/:eleccion/DET/VPP/:distrito/:seccion/:casilla',
    name: 'DetalleDeCasillaVPP',
    component: () => import(/* webpackChunkName: "DetalleDeCasilla" */ '@/views/VotosPorCandidaturas/DetalleDeCasilla.vue'),
  },
  {
    path: '/:eleccion/DPC/:distrito',
    name: 'DetallePorCasilla',
    component: () => import(/* webpackChuckName: "DetallePorCasilla" */ '@/views/DetallePorCasilla.vue'),
  },
  {
    path: '/:eleccion/Secciones/:distrito',
    name: 'Secciones',
    component: () => import(/* webpackChuckName: "Secciones" */ '@/views/Secciones.vue'),
  },
  {
    path: '/:eleccion/Secciones/:distrito/:seccion',
    name: 'DetalleSeccion',
    component: () => import(/* webpackChuckName: "DetalleSeccion" */ '@/views/DetalleSeccion.vue'),
  },
  {
    path: '/:eleccion/Secciones/:distrito/:seccion/:casilla',
    name: 'SeccionCasilla',
    component: () => import(/* webpackChuckName: "SeccionCasilla" */ '@/views/SeccionCasilla.vue'),
  },
  {
    path: '/base-datos',
    name: 'BaseDatos',
    component: () => import(/* webpackChuckName: "BaseDatos" */ '@/views/BaseDatos.vue'),
  },
  {
    path: '/representacion-proporcional/:casilla?',
    name: 'RepresentacionProporcional',
    component: () => import(/* webpackChuckName: "RepresentacionProporcional" */ '@/views/RepresentacionProporcional.vue'),
  },
  {
    path: '/centro-de-ayuda',
    name: 'CentroDeAyuda',
    component: () => import(/* webpackChunkName: "CentroDeAyuda" */ '@/views/CentroDeAyuda/Index.vue'),
  },
  {
    path: '/centro-de-ayuda/normatividad',
    name: 'CentroDeAyudaNormatividad',
    component: () => import(/* webpackChunkName: "CentroDeAyudaNormatividad" */ '@/views/CentroDeAyuda/Normatividad.vue'),
  },
  {
    path: '/centro-de-ayuda/elecciones',
    name: 'CentroDeAyudaElecciones',
    component: () => import(/* webpackChunkName: "CentroDeAyudaElecciones" */ '@/views/CentroDeAyuda/Elecciones/Index.vue'),
  },
  {
    path: '/centro-de-ayuda/votaciones/votos-coalicion',
    name: 'CentroDeAyudaVotacionesVotosCoalicion',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesVotosCoalicion" */ '@/views/CentroDeAyuda/Votaciones/VotosCoalicion.vue'),
  },
  {
    path: '/centro-de-ayuda/votaciones/casillas-electorales',
    name: 'CentroDeAyudaVotacionesCasillasElectorales',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesCasillasElectorales" */ '@/views/CentroDeAyuda/Votaciones/CasillasElectorales.vue'),
  },
  {
    path: '/centro-de-ayuda/votaciones/participacion-ciudadana',
    name: 'CentroDeAyudaVotacionesParticipacionCiudadana',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesParticipacionCiudadana" */ '@/views/CentroDeAyuda/Votaciones/ParticipacionCiudadana.vue'),
  },
  {
    path: '/centro-de-ayuda/votaciones/lista-nominal',
    name: 'CentroDeAyudaVotacionesListaNominal',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesListaNominal" */ '@/views/CentroDeAyuda/Votaciones/ListaNominal.vue'),
  },
  {
    path: '/centro-de-ayuda/prep/conoce/que-es',
    name: 'CentroDeAyudaVotacionesPrepConoceQueEs',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesPrepConoceQueEs" */ '@/views/CentroDeAyuda/Prep/QueEsElPrep.vue'),
  },
  {
    path: '/centro-de-ayuda/prep/conoce/como-opera',
    name: 'CentroDeAyudaVotacionesPrepConoceComoOpera',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesPrepConoceComoOpera" */ '@/views/CentroDeAyuda/Prep/ComoOpera.vue'),
  },
  {
    path: '/centro-de-ayuda/prep/conoce/difusores-oficiales',
    name: 'CentroDeAyudaVotacionesPrepDifusores',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesPrepDifusores" */ '@/views/CentroDeAyuda/Prep/Difusores.vue'),
  },
  {
    path: '/centro-de-ayuda/prep/conoce/linea-tiempo',
    name: 'CentroDeAyudaVotacionesPrepLineaTiempo',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesPrepLineaTiempo" */ '@/views/CentroDeAyuda/Prep/LineaTiempo.vue'),
  },
  {
    path: '/centro-de-ayuda/prep/navegacion/como-navego',
    name: 'CentroDeAyudaVotacionesPrepComoNavego',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesPrepComoNavego" */ '@/views/CentroDeAyuda/Prep/ComoNavego.vue'),
  },
  {
    path: '/centro-de-ayuda/prep/navegacion/elemento-apoyo',
    name: 'CentroDeAyudaVotacionesPrepElementosApoyo',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesPrepElementosApoyo" */ '@/views/CentroDeAyuda/Prep/ElementosApoyo.vue'),
  },
  {
    path: '/centro-de-ayuda/prep/actas/que-es-acta',
    name: 'CentroDeAyudaVotacionesPrepQueEsActaPrep',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesPrepQueEsActaPrep" */ '@/views/CentroDeAyuda/Prep/QueEsActaPrep.vue'),
  },
  {
    path: '/centro-de-ayuda/prep/actas/consultar-imagen',
    name: 'CentroDeAyudaVotacionesPrepConsultarImagen',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesPrepConsultarImagen" */ '@/views/CentroDeAyuda/Prep/ConsultarImagen.vue'),
  },
  {
    path: '/centro-de-ayuda/prep/actas/estatus-digitalizacion-traslado',
    name: 'CentroDeAyudaVotacionesPrepEstatus',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesPrepEstatus" */ '@/views/CentroDeAyuda/Prep/Estatus.vue'),
  },
  {
    path: '/centro-de-ayuda/prep/actas/observaciones',
    name: 'CentroDeAyudaVotacionesPrepObservaciones',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesPrepObservaciones" */ '@/views/CentroDeAyuda/Prep/Observaciones.vue'),
  },
  {
    path: '/centro-de-ayuda/prep/actas/validez',
    name: 'CentroDeAyudaVotacionesPrepValidez',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesPrepValidez" */ '@/views/CentroDeAyuda/Prep/Validez.vue'),
  },
  {
    path: '/centro-de-ayuda/prep/base-de-datos/interpretacion',
    name: 'CentroDeAyudaVotacionesPrepBaseDatos',
    component: () => import(/* webpackChunkName: "CentroDeAyudaVotacionesPrepBaseDatos" */ '@/views/CentroDeAyuda/Prep/BaseDatos.vue'),
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "home" */ '@/views/PaginaNoEncontrada.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
