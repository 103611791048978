/* eslint-disable no-param-reassign */
import Axios from 'axios';
import Cookies from 'js-cookie';
import Vue from 'vue';
import Vuex from 'vuex';
import calcularAnchoDePantalla from '@/utils/cancularAnchoDePantalla';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cargaTerminada: false,
    mostrarMiCasilla: false,
    esOscuro: false,
    esVersionEscritorio: false,
    avanceNacional: {},
    resumenVotos: {
      gubernatura: null,
      distritos: null,
    },
    estadistica: {},
    distritos: {
      uno: {},
    },
    secciones: {},
    casilla: {},
    entidad: {},
    regiones: {},
    anchoPantalla: 0,
    especiales: {},
    zoom: 100,
    votosXCandidato: {},
    estadisticas: {},
    elementosGenerales: {},
  },
  mutations: {
    setearEstado(state, { propiedad, payload, subpropiedad = null }) {
      if (subpropiedad) {
        state[propiedad][subpropiedad] = payload;
        return null;
      }
      state[propiedad] = payload;
      return null;
    },
    cambiarZoom(state, payload) {
      state.zoom = payload;
    },
  },
  getters: {
    obtenerAvance(state) {
      return state.avanceNacional;
    },
    obtenerResumenVotosGubernatura(state) {
      return state.resumenVotos.gubernatura;
    },
    obtenerEstadistica(state) {
      return state.estadistica;
    },
  },
  actions: {
    inicializar({ commit }) {
      const votosXCandidatoTarjeta = Axios.get('/js/json/gubernatura_votos_candidaturas_vista_tarjetas_nivel_entidad.json');
      const votosXCandidatoGraficas = Axios.get('/js/json/gubernatura_votos_candidaturas_vista_graficas_nivel_entidad.json');
      const gVotosXPartidoTarjeta = Axios.get('/js/json/gubernatura_votos_partidos_politicos_con_voto_extranjero_vista_tarjetas_nivel_entidad.json');
      const gVotosXPartidoGraficas = Axios.get('/js/json/gubernatura_votos_partidos_politicos_con_voto_extranjero_vista_graficas_nivel_entidad.json');
      const aVotosXPartidoGraficas = Axios.get('/js/json/ayuntamiento_votos_partidos_politicos_vista_graficas_nivel_entidad.json');
      const aVotosXPartidoTarjeta = Axios.get('/js/json/ayuntamiento_votos_partidos_politicos_vista_tarjetas_nivel_entidad.json');
      const dVotosXPartidoGraficas = Axios.get('/js/json/diputaciones_votos_partidos_politicos_vista_graficas_nivel_entidad.json');
      const dVotosXPartidoTarjeta = Axios.get('/js/json/diputaciones_votos_partidos_politicos_vista_tarjetas_nivel_entidad.json');
      const elementosGenerales = Axios.get('/js/json/archivo_elementos_generales.json');
      const gEstadisticasNivelEntidad = Axios.get('/js/json/gubernatura_estadistica_votacion_nivel_entidad.json');
      const gResumenNivelEntidad = Axios.get('/js/json/gubernatura_resumen_votacion_con_voto_extranjero_nivel_entidad.json');
      const gVotosXCandidatoDistritoTarjeta = Axios.get('/js/json/gubernatura_votos_candidaturas_vista_tarjetas_nivel_distrito.json');
      const dVotosXDistritoTarjeta = Axios.get('/js/json/diputaciones_votos_candidaturas_vista_tarjetas_nivel_distrito.json');
      const gResumenNivelDistrito = Axios.get('/js/json/gubernatura_resumen_votacion_nivel_distrito.json');
      const dResumenNivelEntidad = Axios.get('/js/json/diputaciones_resumen_votacion_nivel_entidad.json');
      const gDistribucionVotosCandidaturasDistrito = Axios.get('/js/json/gubernatura_distribucion_votos_candidaturas_nivel_distrito.json');
      const gVotosXCandidaturasXDistritoXSeccion = Axios.get('/js/json/gubernatura_votos_candidaturas_vista_tarjetas_nivel_seccion.json');
      const gEstadisticasNivelDistrito = Axios.get('/js/json/gubernatura_estadistica_votacion_nivel_distrito.json');
      const gdResumenNivelSeccion = Axios.get('/js/json/gubernatura_resumen_votacion_nivel_seccion.json');
      const gVotosXSeccionNivelSeccion = Axios.get('/js/json/gubernatura_votos_por_seccion_nivel_seccion.json');
      const dEstadisticasNivelEntidad = Axios.get('/js/json/diputaciones_estadistica_votacion_nivel_entidad.json');
      const aEstadisticasNivelEntidad = Axios.get('/js/json/ayuntamiento_estadistica_votacion_nivel_entidad.json');
      const aResumenNivelEntidad = Axios.get('js/json/ayuntamiento_resumen_votacion_nivel_entidad.json');
      const gEstadisticasNivelCasilla = Axios.get('js/json/gubernatura_estadistica_por_casilla.json');
      const gParticipacionCiudadanaNivelDistrito = Axios.get('js/json/gubernatura_participacion_ciudadana_nivel_distrito.json');
      const gVotosXCasillaNivelDistrito = Axios.get('js/json/gubernatura_votos_por_casilla_nivel_distrito.json');
      const gVotosXPartidosNivelDistrito = Axios.get('js/json/gubernatura_votos_candidaturas_combinaciones_vista_tarjetas_nivel_distrito.json');
      const gDistribucionVotosXPPNivelDistrito = Axios.get('js/json/gubernatura_distribucion_votos_por_partido_politico_nivel_distrito.json');
      const aEstadisticasNivelDistrito = Axios.get('/js/json/ayuntamiento_estadistica_votacion_nivel_municipio.json');
      const dEstadisticasNivelDistrito = Axios.get('/js/json/diputaciones_estadistica_votacion_nivel_distrito.json');
      const aEstadisticasNivelCasilla = Axios.get('js/json/ayuntamiento_estadistica_por_casilla_nivel_municipio_seccion.json');
      const dEstadisticasNivelCasilla = Axios.get('js/json/diputaciones_estadistica_por_casilla.json');
      const dVotosXPartidosNivelDistrito = Axios.get('js/json/diputaciones_votos_candidaturas_combinaciones_vista_tarjetas_nivel_distrito.json');
      const dResumenNivelDistrito = Axios.get('/js/json/diputaciones_resumen_votacion_nivel_distrito.json');
      const aResumenNivelDistrito = Axios.get('/js/json/ayuntamiento_resumen_votacion_nivel_municipio.json');
      const aVotosXCandidatoDistritoTarjeta = Axios.get('/js/json/ayuntamiento_votos_candidatos_vista_tarjetas_nivel_municipio.json');
      const aDistribucionVotosCandidaturasDistrito = Axios.get('/js/json/ayuntamiento_distribucion_votos_candidaturas_nivel_municipio.json');
      const dDistribucionVotosCandidaturasDistrito = Axios.get('/js/json/diputaciones_distribucion_votos_candidaturas_nivel_distrito.json');
      const aDistribucionVotosXPPNivelDistrito = Axios.get('js/json/ayuntamiento_distribucion_votos_por_partido_politico_nivel_municipio.json');
      const dDistribucionVotosXPPNivelDistrito = Axios.get('js/json/diputaciones_distribucion_votos_por_partido_politico_nivel_distrito.json');
      const aVotosXCasillaNivelDistrito = Axios.get('js/json/ayuntamiento_votos_por_casilla_vista_tarjetas_nivel_municipio_seccion.json');
      const dVotosXCasillaNivelDistrito = Axios.get('js/json/diputaciones_votos_por_casilla_nivel_distrito.json');
      const aParticipacionCiudadanaNivelDistrito = Axios.get('js/json/ayuntamiento_votos_por_seccion_nivel_municipio.json');
      const dParticipacionCiudadanaNivelDistrito = Axios.get('js/json/diputaciones_participacion_ciudadana_nivel_distrito.json');
      const aVotosXSeccionNivelSeccion = Axios.get('/js/json/ayuntamiento_votos_por_seccion_nivel_municipio.json');
      const dVotosXSeccionNivelSeccion = Axios.get('/js/json/diputaciones_votos_por_seccion_nivel_seccion.json');
      const aResumenNivelSeccion = Axios.get('/js/json/ayuntamiento_resumen_votacion_nivel_municipio_seccion.json');
      const dResumenNivelSeccion = Axios.get('/js/json/diputaciones_resumen_votacion_nivel_seccion.json');
      const gVotosXPartidoPoliticoNivelSeccion = Axios.get('/js/json/gubernatura_votos_candidaturas_vista_tarjetas_nivel_seccion.json');
      const gVotosXCasilla = Axios.get('/js/json/gubernatura_votos_por_casilla.json');
      const aVotosXCasilla = Axios.get('/js/json/ayuntamiento_votos_por_casilla_nivel_municipio_seccion.json');
      const dVotosXCasilla = Axios.get('/js/json/diputaciones_votos_por_casilla.json');
      const aVotosXPartidoPoliticoNivelSeccion = Axios.get('/js/json/ayuntamiento_votos_candidaturas_vista_tarjetas_nivel_municipio_seccion.json');
      const dVotosXPartidoPoliticoNivelSeccion = Axios.get('/js/json/diputaciones_votos_candidaturas_vista_tarjetas_nivel_seccion.json');
      const dVotosMapa = Axios.get('/js/json/diputaciones_votos_distritos_obtenidos_vista_mapa_nivel_entidad.json');
      const aVotosMapa = Axios.get('/js/json/ayuntamiento_votos_municipios_obtenidos_vista_mapa_nivel_entidad.json');
      const gResumenNivelCasilla = Axios.get('/js/json/gubernatura_resumen_por_casilla.json');
      const gEstadisticaNivelCasilla = Axios.get('/js/json/gubernatura_estadistica_por_casilla.json');
      const aResumenNivelCasilla = Axios.get('/js/json/ayuntamiento_resumen_por_casilla_nivel_municipio_seccion.json');
      const dResumenNivelCasilla = Axios.get('/js/json/diputaciones_resumen_por_casilla.json');
      const aEstadisticaNivelCasilla = Axios.get('/js/json/ayuntamiento_estadistica_por_casilla_nivel_municipio_seccion.json');
      const dEstadisticaNivelCasilla = Axios.get('/js/json/diputaciones_estadistica_por_casilla.json');
      const gVotosXCasillaTarjeta = Axios.get('/js/json/gubernatura_votos_por_casilla_vista_tarjetas.json');
      const dVotosMapaTarjetas = Axios.get('/js/json/diputaciones_votos_distritos_obtenidos_vista_mapa_tarjetas_nivel_entidad.json');
      const aVotosMapaTarjetas = Axios.get('/js/json/ayuntamiento_votos_municipios_obtenidos_vista_mapa_tarjetas_nivel_entidad.json'); // Atualizar nombre
      const dVotosXCandidatoDistritoTarjeta = Axios.get('/js/json/diputaciones_votos_candidaturas_vista_tarjetas_nivel_distrito.json');
      const dRepresentacionProporcional = Axios.get('/js/json/diputaciones_votos_por_casilla_especial.json');
      const dResumenNivelRepresentacionProporcional = Axios.get('/js/json/diputaciones_resumen_por_casillas_especiales.json');
      const dTarjetasNivelRepresentacionProporcional = Axios.get('/js/json/diputaciones_votos_por_casilla_especial_vista_tarjetas.json');
      const dVotosXCasillaTarjeta = Axios.get('/js/json/diputaciones_votos_por_casilla_vista_tarjetas.json');
      const aVotosXPartidosNivelDistrito = Axios.get('/js/json/ayuntamiento_votos_candidaturas_combinaciones_vista_tarjetas_nivel_municipio.json');
      const gAvance = Axios.get('/js/json/gubernatura_avance_votacion_nivel_entidad.json');
      const dAvance = Axios.get('/js/json/diputaciones_avance_votacion_nivel_entidad.json');
      const aAvance = Axios.get('/js/json/ayuntamiento_avance_votacion_nivel_entidad.json');
      const gVotoExtranjeroTarjetas = Axios.get('/js/json/gubernatura_votos_por_casilla_del_extranjero_vista_tarjetas.json');
      const gResumenVotoExtranjero = Axios.get('/js/json/gubernatura_resumen_votacion_del_extranjero.json');
      const gVotosExtranjeroXCasilla = Axios.get('/js/json/gubernatura_votos_por_casilla_del_extranjero_vista_tarjetas.json');
      const gEstadisticasVotoExtranjero = Axios.get('/js/json/gubernatura_estadistica_por_casilla_del_extranjero.json');
      const gVotosXPartidoPoliticoVistaTargetasNivelDistrito = Axios.get('/js/json/gubernatura_votos_partido_politicos_vista_tarjetas_nivel_distrito.json');
      const dVotosXPartidoPoliticoVistaTargetasNivelDistrito = Axios.get('/js/json/diputaciones_votos_partido_politicos_vista_tarjetas_nivel_distrito.json');
      const aVotosXPartidoPoliticoVistaTargetasNivelMunicipio = Axios.get('/js/json/ayuntamiento_votos_partido_politicos_vista_tarjetas_nivel_municipio.json');
      const dResumenNivelEntidadConRP = Axios.get('/js/json/diputaciones_resumen_votacion_por_partido_politico_nivel_entidad.json');
      const gVotosXCandidatoCombinacionesDistritoTarjeta = Axios.get('/js/json/gubernatura_votos_candidaturas_combinaciones_vista_tarjetas_nivel_distrito.json');
      const aVotosXCandidatoCombinacionesDistritoTarjeta = Axios.get('/js/json/ayuntamiento_votos_candidaturas_combinaciones_vista_tarjetas_nivel_municipio.json');
      const dVotosXCandidatoCombinacionesDistritoTarjeta = Axios.get('/js/json/diputaciones_votos_candidaturas_combinaciones_vista_tarjetas_nivel_distrito.json');

      const elementosEstado = [
        'votosXCandidatoTarjeta',
        'votosXCandidatoGraficas',
        'gVotosXPartidoTarjeta',
        'gVotosXPartidoGraficas',
        'elementosGenerales',
        'gEstadisticasNivelEntidad',
        'gResumenNivelEntidad',
        'gVotosXCandidatoDistritoTarjeta',
        'dVotosXDistritoTarjeta',
        'gResumenNivelDistrito',
        'dResumenNivelEntidad',
        'gDistribucionVotosCandidaturasDistrito',
        'gVotosXCandidaturasXDistritoXSeccion',
        'gEstadisticasNivelDistrito',
        'gdResumenNivelSeccion',
        'gVotosXSeccionNivelSeccion',
        'dEstadisticasNivelEntidad',
        'aEstadisticasNivelEntidad',
        'aResumenNivelEntidad',
        'aVotosXPartidoTarjeta',
        'aVotosXPartidoGraficas',
        'dVotosXPartidoTarjeta',
        'dVotosXPartidoGraficas',
        'gEstadisticasNivelCasilla',
        'gParticipacionCiudadanaNivelDistrito',
        'gVotosXCasillaNivelDistrito',
        'gVotosXPartidosNivelDistrito',
        'gDistribucionVotosXPPNivelDistrito',
        'aEstadisticasNivelDistrito',
        'dEstadisticasNivelDistrito',
        'aEstadisticasNivelCasilla',
        'dEstadisticasNivelCasilla',
        'dVotosXPartidosNivelDistrito',
        'dResumenNivelDistrito',
        'aResumenNivelDistrito',
        'aVotosXCandidatoDistritoTarjeta',
        'aDistribucionVotosCandidaturasDistrito',
        'dDistribucionVotosCandidaturasDistrito',
        'aDistribucionVotosXPPNivelDistrito',
        'dDistribucionVotosXPPNivelDistrito',
        'aVotosXCasillaNivelDistrito',
        'dVotosXCasillaNivelDistrito',
        'aParticipacionCiudadanaNivelDistrito',
        'dParticipacionCiudadanaNivelDistrito',
        'aVotosXSeccionNivelSeccion',
        'dVotosXSeccionNivelSeccion',
        'aResumenNivelSeccion',
        'dResumenNivelSeccion',
        'gVotosXPartidoPoliticoNivelSeccion',
        'gVotosXCasilla',
        'aVotosXCasilla',
        'dVotosXCasilla',
        'aVotosXPartidoPoliticoNivelSeccion',
        'dVotosXPartidoPoliticoNivelSeccion',
        'dVotosMapa',
        'aVotosMapa',
        'gResumenNivelCasilla',
        'gEstadisticaNivelCasilla',
        'aResumenNivelCasilla',
        'dResumenNivelCasilla',
        'aEstadisticaNivelCasilla',
        'dEstadisticaNivelCasilla',
        'gVotosXCasillaTarjeta',
        'dVotosMapaTarjetas',
        'aVotosMapaTarjetas',
        'dRepresentacionProporcional',
        'dVotosXCandidatoDistritoTarjeta',
        'dResumenNivelRepresentacionProporcional',
        'dVotosXCasillaTarjeta',
        'aVotosXPartidosNivelDistrito',
        'gAvance',
        'dAvance',
        'aAvance',
        'gVotoExtranjeroTarjetas',
        'gResumenVotoExtranjero',
        'gVotosExtranjeroXCasilla',
        'gEstadisticasVotoExtranjero',
        'dTarjetasNivelRepresentacionProporcional',
        'gVotosXPartidoPoliticoVistaTargetasNivelDistrito',
        'dVotosXPartidoPoliticoVistaTargetasNivelDistrito',
        'aVotosXPartidoPoliticoVistaTargetasNivelMunicipio',
        'dResumenNivelEntidadConRP',
        'gVotosXCandidatoCombinacionesDistritoTarjeta',
        'aVotosXCandidatoCombinacionesDistritoTarjeta',
        'dVotosXCandidatoCombinacionesDistritoTarjeta',
      ];

      Promise.all([
        votosXCandidatoTarjeta,
        votosXCandidatoGraficas,
        gVotosXPartidoTarjeta,
        gVotosXPartidoGraficas,
        elementosGenerales,
        gEstadisticasNivelEntidad,
        gResumenNivelEntidad,
        gVotosXCandidatoDistritoTarjeta,
        dVotosXDistritoTarjeta,
        gResumenNivelDistrito,
        dResumenNivelEntidad,
        gDistribucionVotosCandidaturasDistrito,
        gVotosXCandidaturasXDistritoXSeccion,
        gEstadisticasNivelDistrito,
        gdResumenNivelSeccion,
        gVotosXSeccionNivelSeccion,
        dEstadisticasNivelEntidad,
        aEstadisticasNivelEntidad,
        aResumenNivelEntidad,
        aVotosXPartidoTarjeta,
        aVotosXPartidoGraficas,
        dVotosXPartidoTarjeta,
        dVotosXPartidoGraficas,
        gEstadisticasNivelCasilla,
        gParticipacionCiudadanaNivelDistrito,
        gVotosXCasillaNivelDistrito,
        gVotosXPartidosNivelDistrito,
        gDistribucionVotosXPPNivelDistrito,
        aEstadisticasNivelDistrito,
        dEstadisticasNivelDistrito,
        aEstadisticasNivelCasilla,
        dEstadisticasNivelCasilla,
        dVotosXPartidosNivelDistrito,
        dResumenNivelDistrito,
        aResumenNivelDistrito,
        aVotosXCandidatoDistritoTarjeta,
        aDistribucionVotosCandidaturasDistrito,
        dDistribucionVotosCandidaturasDistrito,
        aDistribucionVotosXPPNivelDistrito,
        dDistribucionVotosXPPNivelDistrito,
        aVotosXCasillaNivelDistrito,
        dVotosXCasillaNivelDistrito,
        aParticipacionCiudadanaNivelDistrito,
        dParticipacionCiudadanaNivelDistrito,
        aVotosXSeccionNivelSeccion,
        dVotosXSeccionNivelSeccion,
        aResumenNivelSeccion,
        dResumenNivelSeccion,
        gVotosXPartidoPoliticoNivelSeccion,
        gVotosXCasilla,
        aVotosXCasilla,
        dVotosXCasilla,
        aVotosXPartidoPoliticoNivelSeccion,
        dVotosXPartidoPoliticoNivelSeccion,
        dVotosMapa,
        aVotosMapa,
        gResumenNivelCasilla,
        gEstadisticaNivelCasilla,
        aResumenNivelCasilla,
        dResumenNivelCasilla,
        aEstadisticaNivelCasilla,
        dEstadisticaNivelCasilla,
        gVotosXCasillaTarjeta,
        dVotosMapaTarjetas,
        aVotosMapaTarjetas,
        dRepresentacionProporcional,
        dVotosXCandidatoDistritoTarjeta,
        dResumenNivelRepresentacionProporcional,
        dVotosXCasillaTarjeta,
        aVotosXPartidosNivelDistrito,
        gAvance,
        dAvance,
        aAvance,
        gVotoExtranjeroTarjetas,
        gResumenVotoExtranjero,
        gVotosExtranjeroXCasilla,
        gEstadisticasVotoExtranjero,
        dTarjetasNivelRepresentacionProporcional,
        gVotosXPartidoPoliticoVistaTargetasNivelDistrito,
        dVotosXPartidoPoliticoVistaTargetasNivelDistrito,
        aVotosXPartidoPoliticoVistaTargetasNivelMunicipio,
        dResumenNivelEntidadConRP,
        gVotosXCandidatoCombinacionesDistritoTarjeta,
        aVotosXCandidatoCombinacionesDistritoTarjeta,
        dVotosXCandidatoCombinacionesDistritoTarjeta,
      ])
        .then((responses) => {
          elementosEstado.forEach((estado, index) => {
            commit('setearEstado', { propiedad: estado, payload: responses[index].data });
          });
          commit('setearEstado', { propiedad: 'cargaTerminada', payload: true });
          // Aqui hacemos que el ancho de pantalla sea calculao de forma global por Vuex
          calcularAnchoDePantalla(this);
        });

      if (!Cookies.get('oscuro')) {
        Cookies.set('oscuro', null);
      }

      if (Cookies.get('oscuro') === 'true') {
        commit('setearEstado', { propiedad: 'esOscuro', payload: true });
      }
    },
  },
  modules: {
  },
});
