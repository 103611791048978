function calcularAnchoDePantalla(handler) {
  window.addEventListener('resize', () => {
    if (!handler.state.esVersionEscritorio) {
      handler.commit('setearEstado', {
        propiedad: 'anchoPantalla',
        payload: window.innerWidth,
      });
    }
  });

  if (handler.state.anchoPantalla === 0) {
    handler.commit('setearEstado', {
      propiedad: 'anchoPantalla',
      payload: window.innerWidth,
    });
  }
  // console.log(window.innerWidth)
}

export default calcularAnchoDePantalla;
